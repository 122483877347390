@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
* {
  transition: all 300ms ease-in-out;
}
body {
  background-color: #c9dabf;
}
.home {
  background-image: url("asset/waduk.jpg");
  opacity: 70%;
}
.about-section-container {
  margin-top: 5rem;
  margin-bottom: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #c9dabf;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #808d7c;
  font-size: 1.15rem;
  margin-bottom: 1.5rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.primary-heading {
  box-sizing: border-box;
  margin-right: 15rem;
}
